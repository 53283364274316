<template v-show="false">
  <v-container
    id="dashboard"
    fluid
    tag="section"
  >
    <v-card
      class="mt-0 px-6"
      height="100%"
    >
      <v-row class="fill-height">
        <v-col
          class="fill-height"
          md="3"
        >
          <template v-for="(field, indx) in fields">
            <v-textarea
              v-if="!field.hideinleft"
              :key="'field-' + indx"
              v-model="field.fieldValue"
              :label="field.fieldName"
              :rows="field.rows"
              readonly
              class="font-size-up"
            />
          </template>
          <v-select
            v-if="docsFileList"
            v-model="selectedFile"
            :items="docsFileList"
            item-text="filename"
            label="Файл"
            return-object
            @change="loadFile"
          />
          <!--          <v-btn-->
          <!--            class="ml-auto"-->
          <!--            color="primary"-->
          <!--          >-->
          <!--            согалсовать-->
          <!--          </v-btn>-->
        </v-col>
        <v-col
          md="9"
        >
          <v-textarea
            v-model="fields[11].fieldValue"
            :label="fields[11].fieldName"
            :rows="fields[11].rows"
            class="increase-font-size"
            auto-grow
            row-height="20px"
            readonly
          />
          <docs-viewer
            :image="img"
            :pdf="pdf"
            :img-select="selectedFile"
          />
        </v-col>
      </v-row>
    </v-card>
  </v-container>
</template>

<script>
  export default {
    name: 'InvoiceCheck',
    // eslint-disable-next-line vue/require-prop-types
    components: {
      DocsViewer: () => import('./component/docsViewer'),
    },
    // eslint-disable-next-line vue/require-prop-types
    props: ['id'],
    data () {
      return {
        selectedFile: null,
        itemWikiS: '',
        dataLoading: false,
        fields: [
          {
            fieldName: 'Плательщик',
            fieldLink: 'customertitle',
            fieldValue: null,
            rows: 1,
          },
          {
            fieldName: 'Счет списания',
            fieldLink: 'accounttitle',
            fieldValue: null,
            rows: 2,
          },
          {
            fieldName: 'Сумма по счету',
            fieldLink: 'summ',
            fieldValue: null,
            rows: 1,
          },
          {
            fieldName: 'Сумма НДС',
            fieldLink: 'ndssumm',
            fieldValue: null,
            rows: 1,
          },
          {
            fieldName: 'Получатель',
            fieldLink: 'vendortitle',
            fieldValue: null,
            rows: 1,
          },
          {
            fieldName: 'Получатель ИНН',
            fieldLink: 'vendorinn',
            fieldValue: null,
            rows: 1,
          },
          {
            fieldName: 'Получатель КПП',
            fieldLink: 'vendorkpp',
            fieldValue: null,
            rows: 1,
          },
          {
            fieldName: 'Получатель р/счет',
            fieldLink: 'vendoraccount',
            fieldValue: null,
            rows: 1,
          },
          {
            fieldName: 'Банк получателя',
            fieldLink: 'vendorbanktitle',
            fieldValue: null,
            rows: 1,
          },
          {
            fieldName: 'БИК банка получателя',
            fieldLink: 'vendorbankbik',
            fieldValue: null,
            rows: 1,
          },
          {
            fieldName: 'Кор/счет банка получателя',
            fieldLink: 'vendorbankcoraccount',
            fieldValue: null,
            rows: 1,
          },
          {
            fieldName: 'Назначение платежа',
            fieldLink: 'fullpurpose',
            fieldValue: null,
            rows: 3,
            hideinleft: true,
          },
        ],
        // img: require('../../assets/sidebar-1.jpg'),
        img: null,
        pdf: null,
        docsFileList: null,
      }
    },
    computed: {
    },
    mounted () {
      this.dataLoading = true
      this.$store.dispatch('getPostingDocument', this.id).then((resp) => {
        console.log('111', resp)
        this.fields.forEach((field) => { field.fieldValue = resp[field.fieldLink] })
        this.docsFileList = resp.fileslist
        this.selectedFile = resp.fileslist.find(x => x.fileid === resp.invoicefileid)
        this.$store.dispatch('getPostingFile', { guid: this.id, fileid: this.selectedFile.fileid }).then((file) => {
          if (this.selectedFile.fileextension === 'pdf') {
            this.pdf = window.URL.createObjectURL(new File([file], 'pdf', { type: 'application/pdf' }))
          }
          if (this.selectedFile.fileextension === 'jpg') {
            this.img = window.URL.createObjectURL(new File([file], 'img', { type: 'image/jpeg' }))
          }
          if (this.selectedFile.fileextension === 'png') {
            this.img = window.URL.createObjectURL(new File([file], 'img', { type: 'image/png' }))
          }
          // this.img = window.URL.createObjectURL(new File([resp], 'img', { type: 'image/jpeg' }))
        })
      })
    },
    methods: {
      loadFile () {
        this.pdf = null
        this.img = null
        this.$store.dispatch('getPostingFile', { guid: this.id, fileid: this.selectedFile.fileid }).then((file) => {
          if (this.selectedFile.fileextension === 'pdf') {
            this.pdf = window.URL.createObjectURL(new File([file], 'pdf', { type: 'application/pdf' }))
          }
          if (this.selectedFile.fileextension === 'jpg') {
            this.img = window.URL.createObjectURL(new File([file], 'img', { type: 'image/jpeg' }))
          }
          if (this.selectedFile.fileextension === 'png') {
            this.img = window.URL.createObjectURL(new File([file], 'img', { type: 'image/png' }))
          }
          // this.img = window.URL.createObjectURL(new File([resp], 'img', { type: 'image/jpeg' }))
        })
      },
    },
  }
</script>

<style>
  .increase-font-size.theme--light.v-input textarea {
    font-size: 1.8rem;
  }
  .font-size-up.theme--light.v-input textarea {
    font-size: 1.3rem;
  }
</style>
